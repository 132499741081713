export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 264,
            //             phoneElementId: 272,
            //             descList: [
            //                 {
            //                     descName: '防爆消防灭火侦察机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP66',
            //                     sort: 3
            //                 }, {
            //                     descName: '4.5kN',
            //                     sort: 4
            //                 }, {
            //                     descName: '80L/s',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 6
            //                 }, {
            //                     descName: '防护等级',
            //                     sort: 7
            //                 }, {
            //                     descName: '拖拽力',
            //                     sort: 8
            //                 }, {
            //                     descName: '水泡流量',
            //                     sort: 9
            //                 }],
            //             type: 'video',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 265,
            //             phoneElementId: 273,
            //             descList: [
            //                 {
            //                     descName: '防爆消防灭火侦察机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '集成灭火和侦察等功能，具有越障能力强、拖拽能力强的特点，融合图像采集、热成像检测、环境检测、环境探测等多项前沿技术，能够代替消防人员进入危险区域进行信息获取、控火、灭火等作业，为战斗决策提供可靠的决策支撑，对多种有毒气体进行实时监测，有效保障消防人员的生命安全。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 266,
            //             phoneElementId: 274,
            //             descList: [
            //                 {
            //                     descName: '消防灭火',
            //                     sort: 1
            //                 }, {
            //                     descName: '配备的大功率电机可以将消防水袋施境至危险火源区域进行灭火，能够远程控制发射水或泡沫，通过直流状或质雾开花式双重形式射水，能够水平270"转角，俯仰120"转角，流量可达80L/s，水射程超过80m，泡沫混合液射程超过65m。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 267,
            //             phoneElementId: 275,
            //             descList: [
            //                 {
            //                     descName: '消防侦察',
            //                     sort: 1
            //                 }, {
            //                     descName: '具备远程侦察能力，在进入危险区域前，可远程控制机人进行现场侦察。机身搭建云台、高清摄像头、热成像摄像头、气体传感器三类环境感知设备和云台设备，无需近距离靠近现场即可学握现场图像、视频、声音、温湿度和有毒有害气体等环境因素，在降低自身风险的同时为快速制定各类搜救措施提供强有力的辅助支撑，也可联动声光报警模块提照消防人员或群众，有效避免危险。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 268,
            //             phoneElementId: 276,
            //             descList: [
            //                 {
            //                     descName: '环境温湿监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '机器人对环境的温湿度进行监测，实时传输到遥控器上，可远程了解现场的温湿度环境情况。',
            //                     sort: 2
            //                 },],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 269,
            //             phoneElementId: 277,
            //             descList: [
            //                 {
            //                     descName: '灾场温度监测',
            //                     sort: 1
            //                 }, {
            //                     descName: '机器人对灾场温度进行监测，实时传输到遥控器上，可远程了解灾场温度情况。',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 270,
            //             phoneElementId: 278,
            //             descList: [
            //                 {
            //                     descName: '防爆消防灭火侦察机器人',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: '',
            //                     sort: 1
            //                 },
            //                 {
            //                     descName: '外形尺寸(长×宽×高)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: '防爆等级',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: '行走速度',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: '最大跨越',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: '牵引力',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: '水泡射程',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: '整备质量',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: '防护等级',
            //                     sort: 9
            //                 }, {
            //                     descName: '爬坡能力',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: '涉水深度',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: '水泡流量',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: '续航能力',
            //                     sort: 13
            //                 }, {
            //                     descName: '1490mm×870mm×1390mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'Ex d IIB T6 Gb',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '0-1.5m/s，速度可调',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '350mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: '≥4.5kN',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: '≥80m（水），≥65m（泡沫混合液）',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤780kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: 'IP66',
            //                     sort: 21
            //                 }, {
            //                     descName: '≥35°',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '200mm',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '80L/s',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '持续喷水时间不小于5h或持续行走时间（不拖水带）不小于2h',
            //                     sort: 25
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: 271,
            //             phoneElementId: 279,
            //             descList: [
            //                 {
            //                     descName: '防爆消防灭火侦察机器人',
            //                     sort: 1
            //                 }, {
            //                     descName: '智能侦察，高效灭火',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 8
            //         }
            //     ],
            elmentList:[],
        }
    },
    mounted() {
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        //    this.$store.dispatch("pageset/getSourceData",
        //             {
        //                 createBy: '',
        //                 updateBy: '',
        //                 elmentList: this.elmentList,
        //                 pageId: this.$route.query.pageId,//页面id
        //                 pageName: this.$route.path,
        //                 path: this.$route.path,
        //                 remark: '',
        //             }
        //         );

        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            setTimeout(() => {
                if (!this.$store.state.pageset.pcshow) {
                    let video = document.getElementById('videoOne');
                    video.play();
                  }
            }, 100);
        });
    },
    methods: {
        retention() {
            this.$router.push('/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },
    }
}